import React from 'react';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';
import {
  PeopleFill,
  GridFill,
  Upload,
  ArrowRight,
  Receipt,
  GraphUpArrow,
  BoxArrowInRight,
} from 'react-bootstrap-icons';
import 'react-pro-sidebar/dist/css/styles.css';
import logo from '../../assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/userActions';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  let activeClassName = 'bg-primary rounded text-white';

  return (
    <ProSidebar
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
      width={240}
      className='bg-primary p-2 text-dark bg-opacity-10'
    >
      <SidebarHeader className='border-0'>
        <img src={logo} alt='SBPP' height={60} width={60} />
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape='square'>
          <MenuItem
            icon={<GridFill color='currentColor' size={22} />}
            className={
              location.pathname === '/' ? activeClassName : undefined
            }
          >
            <NavLink
              to='/'
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
            >
              Dashboard
            </NavLink>
          </MenuItem>

          <MenuItem
            icon={<PeopleFill color='currentColor' size={22} />}
            className={
              location.pathname === '/contacts'
                ? activeClassName
                : undefined
            }
          >
            <NavLink
              to='/contacts'
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
            >
              Contacts
            </NavLink>
          </MenuItem>
          <MenuItem
            icon={<Upload color='currentColor' size={22} />}
            className={
              location.pathname === '/accounts'
                ? activeClassName
                : undefined
            }
          >
            <NavLink
              to='/accounts'
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
            >
              Accounts
            </NavLink>
          </MenuItem>
          <MenuItem
            icon={<Receipt color='currentColor' size={22} />}
            className={
              location.pathname === '/receipts'
                ? activeClassName
                : undefined
            }
          >
            <NavLink
              to='/receipts'
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
            >
              Receipts
            </NavLink>
          </MenuItem>
          <MenuItem
            icon={<GraphUpArrow color='currentColor' size={22} />}
            className={
              location.pathname === '/reports'
                ? activeClassName
                : undefined
            }
          >
            <NavLink
              to='/reports'
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
            >
              Reports
            </NavLink>
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter className='border-0'>
        <Menu>
          <MenuItem icon={<BoxArrowInRight size='24' />}>
            Logout <Link to='/login' onClick={logoutHandler} />
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
