import { Modal } from 'antd';
import React, { useState } from 'react';

const LicenseStatusModal = (props) => {
  const licStatus = props.licStatus;
  const licenceRequestFileLocation = props.licenceRequestFileLocation;
  const modalBody =
    licStatus === 'Issued'
      ? 'Your Product is not yet activated!!!'
      : licStatus === 'Expired'
      ? 'Your Product is Expired!!!'
      : licStatus === 'Not Available'
      ? `Please contact Our Technical team with License Request File to get License file . License Request File is located in ${licenceRequestFileLocation}`
      : 'Your Product is not registered!!!';
  const modalHeader =
    licStatus === 'Issued'
      ? 'Issued'
      : licStatus === 'Expired'
      ? 'Expired'
      : licStatus === 'Not Available'
      ? 'Contact Us'
      : 'Not Registered';

  const modalCloseHandler = () => {
    if (licStatus === 'Not Available') {
      props.setUserLevelList(['eAdmin']);
    }
    props.setShowStatusModal(false);
  };
  return (
    <Modal
      title={modalHeader}
      visible={props.showStatusModal}
      onCancel={modalCloseHandler}
    >
      {modalBody}
    </Modal>
  );
};

export default LicenseStatusModal;
