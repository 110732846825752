import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT,
} from '../constants/userConstants';
import { checkAuth } from './authActions';
import Axios from './utils/serverConnection';

export const login = (user) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });
  try {
    const { data } = await Axios.post('/login/login.php', {
      userName: user.userName,
      password: user.password,
    });
    if (data.success === 1) {
      const userInfo = {
        token: data.token,
        ...data.userInfo,
      };
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: {
          userInfo,
          licStatus: data.licStatus,
          licenceRequestFileLocation: data.licenceRequestFileLocation,
        },
      });
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      dispatch(checkAuth());
    } else if (data.success === 0) {
      if (data.licStatus) {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { licStatus: data.licStatus },
        });
      } else {
        throw new Error(data.message);
      }
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo');
  dispatch({
    type: USER_LOGOUT,
  });
};
