import { Button, Form, Input } from 'antd';
import React from 'react';

const ContactForm = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.onSubmit(values);
  };
  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name='firstName'
        label='First Name'
        rules={[
          {
            required: true,
            message: 'Please input your First Name!',
          },
        ]}
        labelCol={{ span: 5 }}
        labelAlign='right'
      >
        <Input className='rounded' size='large'></Input>
      </Form.Item>
      <Form.Item
        name='lastName'
        label='Last Name'
        rules={[
          {
            required: true,
            message: 'Please input your Last Name!',
          },
        ]}
        labelCol={{ span: 5 }}
        labelAlign='right'
      >
        <Input className='rounded' size='large'></Input>
      </Form.Item>
      <Form.Item
        name='company'
        label='Company Name'
        rules={[
          {
            required: true,
            message: 'Please input your Company name!',
          },
        ]}
        labelCol={{ span: 5 }}
        labelAlign='right'
      >
        <Input className='rounded' size='large'></Input>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          className='rounded'
          loading={props.loading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
