import {
  LICENSE_UPLOAD_ERROR,
  LICENSE_UPLOAD_REQUEST,
  LICENSE_UPLOAD_SUCCESS,
} from '../constants/licenseConstants';

import Axios from './utils/serverConnection';

export const uploadLicense = (files) => async (dispatch) => {
  dispatch({ type: LICENSE_UPLOAD_REQUEST });
  try {
    const { data } = await Axios.post(
      '/license/uploadLicense.php',
      files
    );
    if (data.success === 1) {
      dispatch({
        type: LICENSE_UPLOAD_SUCCESS,
      });
    } else if (data.success === 0) {
      throw new Error(data.message);
    }
  } catch (error) {
    dispatch({
      type: LICENSE_UPLOAD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
