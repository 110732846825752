import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { uploadLicense } from '../../actions/licenseActions';
import { useDispatch, useSelector } from 'react-redux';
import { LICENSE_UPLOAD_RESET } from '../../constants/licenseConstants';
import { Form, Modal, notification } from 'antd';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileEncode
);
const LicenseUpload = (props) => {
  const { loading, error, success } = useSelector(
    (state) => state.uploadLicense
  );

  const [licenseFile, setLicenseFile] = useState([]);
  const [databaseFile, setDatabaseFile] = useState([]);
  const [licenseFileData, setLicenseFileData] = useState('');
  const [databaseFileData, setDatabaseFileData] = useState('');

  const dispatch = useDispatch();
  const modalCloseHandler = useCallback(() => {
    props.setShowLicenseUploadModal(false);
  }, [props]);
  const successNotify = useCallback(
    () =>
      notification.success({
        message: 'License is Uploaded successfully',
        onClose: () => {
          dispatch({ type: LICENSE_UPLOAD_RESET });
          window.location.href = '/';
          modalCloseHandler();
        },
      }),
    [dispatch, modalCloseHandler]
  );

  const errorNotify = useCallback(
    () =>
      notification.error({
        message: error,
        onClose: () => {
          dispatch({ type: LICENSE_UPLOAD_RESET });
        },
      }),
    [dispatch, error]
  );

  useEffect(() => {
    if (success) {
      successNotify();
    }
    if (error) {
      errorNotify();
    }
  }, [success, successNotify, error, errorNotify]);

  const licFileHandler = (files) => {
    if (files.length > 0) {
      if (files[0].fileExtension === 'lic') {
        setLicenseFile(files);
        setLicenseFileData(files[0].getFileEncodeBase64String());
      }
    }
  };
  const sqlFileHandler = (files) => {
    if (files.length > 0) {
      if (files[0].fileExtension === 'sql') {
        setDatabaseFile(files);
        setDatabaseFileData(files[0].getFileEncodeBase64String());
      }
    }
  };

  const uploadHandler = () => {
    if (licenseFile.length > 0 && databaseFile.length > 0) {
      dispatch(
        uploadLicense({
          licenseFile: atob(licenseFileData),
          databaseFile: atob(databaseFileData),
        })
      );
    }
  };

  return (
    <>
      <Modal
        title='License File Upload'
        visible={props.showLicenseUploadModal}
        onCancel={modalCloseHandler}
        onOk={uploadHandler}
        confirmLoading={loading}
        okText={loading ? 'Uploading' : 'Upoload'}
        centered
      >
        <Form>
          <Form.Item label='License File'>
            <FilePond
              files={licenseFile}
              maxFiles={1}
              allowMultiple={false}
              onupdatefiles={(files) => licFileHandler(files)}
              allowFileEncode={true}
            />
          </Form.Item>
          <Form.Item label='Database File'>
            <FilePond
              files={databaseFile}
              maxFiles={1}
              allowMultiple={false}
              onupdatefiles={(files) => sqlFileHandler(files)}
              allowFileEncode={true}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LicenseUpload;
