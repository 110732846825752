import { Row, Typography } from 'antd';
import React from 'react';

const TypoPanel = (props) => {
  return (
    <Row justify='space-between' className='mb-4'>
      <Typography.Title level={4}>{props.title}</Typography.Title>
      {props.children}
    </Row>
  );
};

export default TypoPanel;
