import { notification } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { addReceiptData } from '../../actions/accountsActions';
import { ADD_NEW_RECEIPT_RESET } from '../../constants/accountsConstants';
import TypoPanel from '../UI/TypoPanel';
import NewReceiptForm from './NewReceiptForm';

const NewReceipt = () => {
  const formRef = useRef(null);
  const {
    loading: addLoading,
    error: addError,
    success,
  } = useSelector((state) => state.createReceipt);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successNotify = useCallback(
    () =>
      notification.success({
        message: 'Accounts Updated',
        onClose: () => {
          dispatch({ type: ADD_NEW_RECEIPT_RESET });
        },
      }),
    [dispatch]
  );

  const errorNotify = useCallback(
    () =>
      notification.error({
        message: addError,
        onClose: () => {
          dispatch({ type: ADD_NEW_RECEIPT_RESET });
        },
      }),
    [dispatch, addError]
  );
  useEffect(() => {
    if (success) {
      successNotify();
      resetHandler();
    }
    if (addError) {
      errorNotify();
    }
  }, [
    success,
    dispatch,
    successNotify,
    errorNotify,
    addError,
    navigate,
  ]);
  const resetHandler = () => {
    formRef.current.resetForm();
  };
  const submitHandler = (data) => {
    dispatch(addReceiptData(data));
  };
  return (
    <>
      <TypoPanel title='New Recipt'></TypoPanel>
      <NewReceiptForm
        loading={addLoading}
        ref={formRef}
        onSubmit={submitHandler}
      ></NewReceiptForm>
    </>
  );
};

export default NewReceipt;
