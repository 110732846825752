import { Button, DatePicker, Form, InputNumber, Select } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContacts } from '../../actions/contactActions';

const NewReceiptForm = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { loading, error, contacts } = useSelector(
    (state) => state.listContacts
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!contacts) {
      dispatch(getAllContacts());
    }
  }, [dispatch, contacts]);
  const onFinish = (values) => {
    values = {
      ...values,
      date: values.date.format('DD-MM-YYYY'),
    };
    props.onSubmit(values);
  };
  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
    },
  }));
  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name='date'
        label='Date'
        labelAlign='right'
        rules={[{ required: true, message: 'Please input Date!' }]}
        labelCol={{ span: 5 }}
      >
        <DatePicker
          size='large'
          className='w-100 rounded'
        ></DatePicker>
      </Form.Item>
      <Form.Item
        name='contact'
        label='Contact Name'
        labelAlign='right'
        rules={[
          { required: true, message: 'Please Select Contact!' },
        ]}
        labelCol={{ span: 5 }}
      >
        <Select size='large' className='rounded'>
          {contacts &&
            contacts.map((c) => (
              <Option value={c.contactId} key={c.contactId}>
                {c.firstName + ' ' + c.lastName}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Received'
        labelAlign='right'
        labelCol={{ span: 5 }}
        name='received'
        rules={[
          {
            required: true,
            message: 'Please input  Received!',
          },
        ]}
      >
        <InputNumber
          min={0}
          className='rounded  w-100'
          size='large'
        ></InputNumber>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          className='rounded'
          loading={props.loading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
});

export default NewReceiptForm;
