import { Table } from 'antd';
import React from 'react';
import * as numFormat from 'indian-number-format';

const ReportsDataPanel = (props) => {
  const reportData = props.data;
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      onCell: (_, index) => ({
        colSpan:
          index === 0 || index === reportData.length - 1 ? 6 : 1,
        align:
          index === 0 || index === reportData.length - 1
            ? 'right'
            : 'left',
      }),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      onCell: (_, index) => ({
        align:
          index === 0 || index === reportData.length - 1
            ? 'right'
            : 'left',
      }),
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      onCell: (_, index) => ({
        colSpan:
          index === 0 || index === reportData.length - 1 ? 0 : 1,
      }),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (text) => numFormat.formatFixed(text, 2),
      onCell: (_, index) => ({
        colSpan:
          index === 0 || index === reportData.length - 1 ? 0 : 1,
        align: 'right',
      }),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => numFormat.formatFixed(text, 2),
      onCell: (_, index) => ({
        colSpan:
          index === 0 || index === reportData.length - 1 ? 0 : 1,
        align: 'right',
      }),
    },
    {
      title: 'Received',
      dataIndex: 'received',
      key: 'received',
      render: (text) => numFormat.formatFixed(text, 2),
      onCell: (_, index) => ({
        colSpan:
          index === 0 || index === reportData.length - 1 ? 0 : 1,
        align: 'right',
      }),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (text) => numFormat.formatFixed(text, 2),
      onCell: (_, index) => ({
        colSpan:
          index === 0 || index === reportData.length - 1 ? 0 : 1,
        align: 'right',
      }),
    },
  ];
  return (
    <Table
      size='middle'
      dataSource={reportData}
      pagination={{ position: ['bottomCenter'] }}
      columns={columns}
    ></Table>
  );
};

export default ReportsDataPanel;
