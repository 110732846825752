import {
  FETCH_REPORT_DATA_ERROR,
  FETCH_REPORT_DATA_REQUEST,
  FETCH_REPORT_DATA_SUCCESS,
} from '../constants/reportConstants';
import Axios from './utils/serverConnection';

export const getReportData =
  (filterValues) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
    dispatch({ type: FETCH_REPORT_DATA_REQUEST });

    try {
      const { data } = await Axios.post(
        '/reports/getReport.php',
        filterValues,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      if (data.success === 1) {
        dispatch({
          type: FETCH_REPORT_DATA_SUCCESS,
          payload: {
            reportData: data.reportData,
            prevBalance: data.prevBalance.balance,
          },
        });
      } else if (data.success === 0) {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch({
        type: FETCH_REPORT_DATA_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
