import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAccountsList } from '../../actions/accountsActions';
import { getAllContacts } from '../../actions/contactActions';
import TypoPanel from '../UI/TypoPanel';
import AccountsList from './AccountsList';

const Accounts = () => {
  const { loading, error, accountsData } = useSelector(
    (state) => state.listAccountsData
  );
  const {
    loading: contactsLoading,
    error: contactsError,
    contacts,
  } = useSelector((state) => state.listContacts);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!accountsData) {
      dispatch(getAccountsList());
    }
    if (!contacts) {
      dispatch(getAllContacts());
    }
  }, [accountsData, contacts, dispatch]);
  useEffect(() => {
    if (accountsData && contacts) {
      const accounts = accountsData.map((d, i) => {
        const contact = contacts.find(
          (c) => c.contactId === d.contact
        );
        return {
          ...d,
          key: d.id,
          no: i + 1,
          contact: contact.firstName + '  ' + contact.lastName,
        };
      });
      setData(accounts);
    }
  }, [accountsData, contacts, dispatch]);

  return (
    <>
      <TypoPanel title='Accounts'>
        <Link to='/accounts/add'>
          <Button type='primary' className='rounded'>
            Add Account Data
          </Button>
        </Link>
      </TypoPanel>
      <AccountsList
        error={error}
        loading={loading}
        accountsData={data}
      ></AccountsList>
    </>
  );
};

export default Accounts;
