import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
} from 'antd';
import _ from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContacts } from '../../actions/contactActions';

const NewAccountsForm = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { loading, error, contacts } = useSelector(
    (state) => state.listContacts
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!contacts) {
      dispatch(getAllContacts());
    }
  }, [dispatch, contacts]);
  const fieldsChangeHandler = (field, allFields) => {
    if (
      field[0].name[0] === 'weight' ||
      field[0].name[0] === 'rate'
    ) {
      const rate = _.find(allFields, (all) => all.name[0] === 'rate');
      const weight = _.find(
        allFields,
        (all) => all.name[0] === 'weight'
      );

      const amount = weight.value * rate.value;
      form.setFieldValue('amount', amount);
    }
  };
  const onFinish = (values) => {
    values = {
      ...values,
      date: values.date.format('DD-MM-YYYY'),
    };
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
    },
  }));

  return (
    <Form
      onFinish={onFinish}
      form={form}
      onFieldsChange={fieldsChangeHandler}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name='date'
            label='Date'
            labelAlign='right'
            rules={[
              { required: true, message: 'Please input Date!' },
            ]}
            labelCol={{ span: 5 }}
          >
            <DatePicker
              size='large'
              className='w-100 rounded'
            ></DatePicker>
          </Form.Item>
          <Form.Item
            name='contact'
            label='Contact Name'
            labelAlign='right'
            rules={[
              { required: true, message: 'Please Select Contact!' },
            ]}
            labelCol={{ span: 5 }}
          >
            <Select size='large' className='rounded'>
              {contacts &&
                contacts.map((c) => (
                  <Option value={c.contactId} key={c.contactId}>
                    {c.firstName + ' ' + c.lastName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='weight'
            labelAlign='right'
            labelCol={{ span: 5 }}
            label='Weight'
            rules={[
              {
                required: true,
                message: 'Please input  Weight!',
              },
            ]}
          >
            <InputNumber
              min={0}
              className='rounded  w-100'
              size='large'
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Rate'
            labelAlign='right'
            labelCol={{ span: 5 }}
            name='rate'
            rules={[
              { required: true, message: 'Please input Rate!' },
            ]}
          >
            <InputNumber
              min={0}
              className='rounded  w-100'
              size='large'
            ></InputNumber>
          </Form.Item>
          <Form.Item
            label='Amount'
            labelAlign='right'
            labelCol={{ span: 5 }}
            name='amount'
            rules={[
              {
                required: true,
                message: 'Please input Amount!',
              },
            ]}
          >
            <InputNumber
              min={0}
              readOnly
              className='rounded w-100'
              size='large'
            />
          </Form.Item>
          <Form.Item
            label='Received'
            labelAlign='right'
            labelCol={{ span: 5 }}
            name='received'
            rules={[
              {
                required: true,
                message: 'Please input  Received!',
              },
            ]}
          >
            <InputNumber
              min={0}
              className='rounded  w-100'
              size='large'
            ></InputNumber>
          </Form.Item>
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Form.Item>
            <Button
              htmlType='submit'
              type='primary'
              className='rounded'
              loading={props.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

export default NewAccountsForm;
