import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Sidebar from '../UI/Sidebar';
import '../../styles/sass/App.scss';
import Contacts from '../Contacts/Contacts';
import NewContact from '../Contacts/NewContact';
import EditContact from '../Contacts/EditContact';
import NewAccounts from '../Accounts/NewAccounts';
import Accounts from '../Accounts/Accounts';
import Receipts from '../Receipts/Receipts';
import NewReceipt from '../Receipts/NewReceipt';
import Reports from '../Reports/Reports';
import Login from '../Login/Login';
import PageNotFound from '../UI/PageNotFound';

const AppContainer = () => {
  return (
    <Layout hasSider className='vw-100 min-vh-100 h-100 bg-white'>
      <Sider className='bg-white' width={240}>
        <Sidebar />
      </Sider>
      <Layout className='bg-white p-5 overflow-auto'>
        <Content>
          <Routes>
            <Route path='/' element={<Dashboard />}></Route>
            <Route path='/contacts'>
              <Route index element={<Contacts />}></Route>
              <Route path='add' element={<NewContact />}></Route>
              <Route
                path='edit/:id'
                element={<EditContact />}
              ></Route>
            </Route>
            <Route path='/accounts'>
              <Route index element={<Accounts />} />
              <Route path='add' element={<NewAccounts />}></Route>
            </Route>
            <Route path='/receipts'>
              <Route index element={<Receipts />}></Route>
              <Route path='add' element={<NewReceipt />}></Route>
            </Route>
            <Route path='/reports' element={<Reports />}></Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppContainer;
