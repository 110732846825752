import React from 'react';
import { Pencil, Trash3 } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Space, Table } from 'antd';

const ContactsListTable = (props) => {
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
    { title: 'Company', dataIndex: 'companyName', key: 'company' },

    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space size={'middle'}>
          <Link to={`/accounts/edit/${record.id}`}>
            <Pencil size={16} />
          </Link>
          <Link to={`/accounts/delete/${record.id}`}>
            <Trash3 size={16} />
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Table
      size='middle'
      loading={props.loading}
      dataSource={props.data}
      pagination={{ position: ['bottomCenter'] }}
      columns={columns}
    />
  );
};

export default ContactsListTable;
