import { Alert, Spin } from 'antd';
import React from 'react';
import ContactsListTable from './ContactsListTable';

const ContactsList = (props) => {
  return (
    <>
      {props.loading ? (
        <Spin />
      ) : props.error ? (
        <Alert type='error' message={props.error} banner />
      ) : (
        <ContactsListTable
          data={props.contactsData}
          loading={props.loading}
        ></ContactsListTable>
      )}
    </>
  );
};

export default ContactsList;
