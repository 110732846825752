import { Button, Col, DatePicker, Form, Select } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContacts } from '../../actions/contactActions';

const ReportsForm = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { loading, error, contacts } = useSelector(
    (state) => state.listContacts
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!contacts) {
      dispatch(getAllContacts());
    }
  }, [dispatch, contacts]);
  const finishHandler = (data) => {
    data = {
      ...data,
      date: data.date.map((d) => d.format('DD-MM-YYYY')),
    };
    props.onSubmit(data);
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
    },
  }));

  return (
    <Form
      className='justify-content-between'
      form={form}
      layout='inline'
      onFinish={finishHandler}
    >
      <Col span={8}>
        <Form.Item
          name='contact'
          label='Contact Name'
          rules={[
            { required: true, message: 'Please Select Contact!' },
          ]}
        >
          <Select className='rounded' loading={loading}>
            {contacts &&
              contacts.map((c) => (
                <Option value={c.contactId} key={c.contactId}>
                  {c.firstName + ' ' + c.lastName}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name='date'
          label='Date'
          rules={[{ required: true, message: 'Please Select Date!' }]}
        >
          <RangePicker></RangePicker>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item>
          <Button
            htmlType='submit'
            type='primary'
            className='rounded'
            loading={props.loading}
          >
            Generate
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
});

export default ReportsForm;
