import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getReceiptsList } from '../../actions/accountsActions';
import { getAllContacts } from '../../actions/contactActions';
import TypoPanel from '../UI/TypoPanel';
import ReceiptsList from './ReceiptsList';

const Receipts = () => {
  const { loading, error, receiptsData } = useSelector(
    (state) => state.listReceiptsData
  );
  const {
    loading: contactsLoading,
    error: contactsError,
    contacts,
  } = useSelector((state) => state.listContacts);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!receiptsData) {
      dispatch(getReceiptsList());
    }
    if (!contacts) {
      dispatch(getAllContacts());
    }
  }, [receiptsData, contacts, dispatch]);
  useEffect(() => {
    if (receiptsData && contacts) {
      const accounts = receiptsData.map((d, i) => {
        const contact = contacts.find(
          (c) => c.contactId === d.contact
        );
        return {
          ...d,
          key: d.id,
          no: i + 1,
          contact: contact.firstName + '  ' + contact.lastName,
        };
      });
      setData(accounts);
    }
  }, [receiptsData, contacts, dispatch]);
  return (
    <>
      <TypoPanel title='Receipts'>
        <Link to='/receipts/add'>
          <Button type='primary' className='rounded'>
            Add Receipt
          </Button>
        </Link>
      </TypoPanel>
      <ReceiptsList
        error={error}
        loading={loading}
        receiptsData={data}
      ></ReceiptsList>
    </>
  );
};

export default Receipts;
