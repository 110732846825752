import { Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './actions/authActions';
import AppContainer from './components/App/AppContainer';
import Login from './components/Login/Login';
import './styles/less/App.less';

function App() {
  const { isAuth } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth === undefined) {
      dispatch(checkAuth());
    }
  }, [isAuth, dispatch]);
  return isAuth === undefined ? (
    <div className='d-flex align-items-center justify-content-center vh-100 vw-100'>
      <Spin />
    </div>
  ) : isAuth && userInfo?.token ? (
    <AppContainer />
  ) : (
    <Login />
  );
}

export default App;
