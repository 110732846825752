import { Space, Table } from 'antd';
import React from 'react';
import * as numFormat from 'indian-number-format';
import { Pencil, Trash3 } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const AccountsListTable = (props) => {
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Contact', dataIndex: 'contact', key: 'contact' },
    { title: 'Weight', dataIndex: 'weight', key: 'weight' },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      align: 'right',
      render: (text) => numFormat.formatFixed(text, 2),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text) => numFormat.formatFixed(text, 2),
    },
    {
      title: 'Received',
      dataIndex: 'received',
      key: 'received',
      align: 'right',
      render: (text) => numFormat.formatFixed(text, 2),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space size={'middle'}>
          <Link to={`/accounts/edit/${record.id}`}>
            <Pencil size={16} />
          </Link>
          <Link to={`/accounts/delete/${record.id}`}>
            <Trash3 size={16} />
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Table
      size='middle'
      loading={props.loading}
      dataSource={props.data}
      pagination={{ position: ['bottomCenter'] }}
      columns={columns}
    />
  );
};

export default AccountsListTable;
