import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Row,
  Typography,
} from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { login } from '../../actions/userActions';
import LicenseStatusModal from '../License/LicenseStatusModal';
import LicenseUpload from '../License/LicenseUpload';

const Login = () => {
  const { loading, error, licStatus, licenceRequestFileLocation } =
    useSelector((state) => state.userLogin);

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const [showStatusModal, setShowStatusModal] = useState(false);
  const navigate = useNavigate();

  const [showLicenseUploadModal, setShowLicenseUploadModal] =
    useState(false);
  const errorNotify = useCallback(
    () =>
      notification.error({
        message: error,
      }),
    [error]
  );
  const successNotify = useCallback(
    () =>
      notification.success({
        message: 'logged in successfully',
      }),
    []
  );
  useEffect(() => {
    if (error) {
      errorNotify();
    }
  }, [errorNotify, error]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      licStatus &&
      (licStatus === 'Not Available' ||
        licStatus === 'Issued' ||
        licStatus === 'Expired')
    ) {
      setShowStatusModal(true);
    } else {
      navigate('/');
    }
  }, [licStatus, navigate, successNotify]);

  const submitHandler = async (e) => {
    if (userName === 'eadmin' && password === 'eadmin') {
      setShowLicenseUploadModal(true);
    } else {
      dispatch(login({ userName, password }));
    }
  };

  return (
    <>
      {showStatusModal && (
        <LicenseStatusModal
          showStatusModal={showStatusModal}
          setShowStatusModal={setShowStatusModal}
          licStatus={licStatus}
          licenceRequestFileLocation={licenceRequestFileLocation}
        />
      )}
      {showLicenseUploadModal && (
        <LicenseUpload
          showLicenseUploadModal={showLicenseUploadModal}
          setShowLicenseUploadModal={setShowLicenseUploadModal}
        />
      )}
      <Layout className='vh-100 vw-100 bg-white'>
        <Row md={1} className='h-100' align='middle' justify='center'>
          <Col span={8}>
            <Form
              name='normal_login'
              className='login-form'
              onFinish={submitHandler}
            >
              <Form.Item>
                <Typography.Title className='text-center'>
                  Welcome
                </Typography.Title>
              </Form.Item>
              <Form.Item
                name='username'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ]}
                onChange={(e) => setUserName(e.target.value)}
              >
                <Input prefix={''} placeholder='Username' />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input
                  prefix={''}
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  loading={loading}
                  block
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default Login;
