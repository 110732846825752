import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addContact,
  getAllContacts,
} from '../../actions/contactActions';
import { ADD_NEW_CONTACT_RESET } from '../../constants/contactConstants';
import TypoPanel from '../UI/TypoPanel';
import ContactForm from './ContactForm';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

const NewContact = () => {
  const {
    loading: addLoading,
    error: addError,
    success,
  } = useSelector((state) => state.createContact);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const successNotify = useCallback(
    () =>
      notification.success({
        message: 'Contact is saved',
        onClose: () => {
          dispatch({ type: ADD_NEW_CONTACT_RESET });
        },
      }),
    [dispatch]
  );

  const errorNotify = useCallback(
    () =>
      notification.error({
        message: addError,
        onClose: () => {
          dispatch({ type: ADD_NEW_CONTACT_RESET });
        },
      }),
    [dispatch, addError]
  );

  useEffect(() => {
    if (success) {
      successNotify();
      dispatch(getAllContacts());
      navigate('/contacts');
    }
    if (addError) {
      errorNotify();
    }
  }, [
    success,
    dispatch,
    successNotify,
    errorNotify,
    addError,
    navigate,
  ]);

  const submitHandler = (data) => {
    if (data.firstName && data.lastName && data.company) {
      dispatch(addContact(data));
    }
  };
  return (
    <>
      <TypoPanel title='New Contact'></TypoPanel>
      <ContactForm
        onSubmit={submitHandler}
        loading={addLoading}
      ></ContactForm>
    </>
  );
};

export default NewContact;
