export const ADD_NEW_ACCOUNTS_REQUEST = 'ADD_NEW_ACCOUNTS_REQUEST';
export const ADD_NEW_ACCOUNTS_SUCCESS = 'ADD_NEW_ACCOUNTS_SUCCESS';
export const ADD_NEW_ACCOUNTS_ERROR = 'ADD_NEW_ACCOUNTS_ERROR';
export const ADD_NEW_ACCOUNTS_RESET = 'ADD_NEW_ACCOUNTS_RESET';

export const ADD_NEW_RECEIPT_REQUEST = 'ADD_NEW_RECEIPT_REQUEST';
export const ADD_NEW_RECEIPT_SUCCESS = 'ADD_NEW_RECEIPT_SUCCESS';
export const ADD_NEW_RECEIPT_ERROR = 'ADD_NEW_RECEIPT_ERROR';
export const ADD_NEW_RECEIPT_RESET = 'ADD_NEW_RECEIPT_RESET';

export const FETCH_ALL_ACCOUNTS_REQUEST =
  'FETCH_ALL_ACCOUNTS_REQUEST';
export const FETCH_ALL_ACCOUNTS_SUCCESS =
  'FETCH_ALL_ACCOUNTS_SUCCESS';
export const FETCH_ALL_ACCOUNTS_ERROR = 'FETCH_ALL_ACCOUNTS_ERROR';

export const FETCH_ALL_RECEIPTS_LIST_REQUEST =
  'FETCH_ALL_RECEIPTS_LIST_REQUEST';
export const FETCH_ALL_RECEIPTS_LIST_SUCCESS =
  'FETCH_ALL_RECEIPTS_LIST_SUCCESS';
export const FETCH_ALL_RECEIPTS_LIST_ERROR =
  'FETCH_ALL_RECEIPTS_LIST_ERROR';
