import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import TypoPanel from '../UI/TypoPanel';
import { Link } from 'react-router-dom';
import ContactsList from './ContactsList';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContacts } from '../../actions/contactActions';

const Contacts = () => {
  const { loading, error, contacts } = useSelector(
    (state) => state.listContacts
  );
  const [contactsList, setContactsList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contacts) {
      dispatch(getAllContacts());
    } else {
      const contactsArr = contacts.map((contact, i) => ({
        no: i + 1,
        key: contact.contactId,
        ...contact,
      }));
      setContactsList(contactsArr);
    }
  }, [contacts, dispatch]);

  return (
    <>
      <TypoPanel title='Contacts'>
        <Link to='/contacts/add'>
          <Button type='primary' className='rounded'>
            Add Contact
          </Button>
        </Link>
      </TypoPanel>
      <ContactsList
        error={error}
        loading={loading}
        contactsData={contactsList}
      />
    </>
  );
};

export default Contacts;
