import {
  ADD_NEW_RECEIPT_ERROR,
  ADD_NEW_RECEIPT_REQUEST,
  ADD_NEW_RECEIPT_RESET,
  ADD_NEW_RECEIPT_SUCCESS,
  FETCH_ALL_ACCOUNTS_ERROR,
  FETCH_ALL_ACCOUNTS_REQUEST,
  FETCH_ALL_ACCOUNTS_SUCCESS,
  FETCH_ALL_RECEIPTS_LIST_ERROR,
  FETCH_ALL_RECEIPTS_LIST_REQUEST,
  FETCH_ALL_RECEIPTS_LIST_SUCCESS,
} from '../constants/accountsConstants';
import {
  ADD_NEW_ACCOUNTS_ERROR,
  ADD_NEW_ACCOUNTS_REQUEST,
  ADD_NEW_ACCOUNTS_RESET,
  ADD_NEW_ACCOUNTS_SUCCESS,
} from '../constants/accountsConstants';

export const uploadAccountsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_ACCOUNTS_REQUEST:
      return { loading: true };
    case ADD_NEW_ACCOUNTS_SUCCESS:
      return { loading: false, success: true };
    case ADD_NEW_ACCOUNTS_ERROR:
      return { loading: false, error: action.payload };
    case ADD_NEW_ACCOUNTS_RESET:
      return {};
    default:
      return state;
  }
};

export const listAccountsDataReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALL_ACCOUNTS_REQUEST:
      return { loading: true };
    case FETCH_ALL_ACCOUNTS_SUCCESS:
      return { loading: false, accountsData: action.payload };
    case FETCH_ALL_ACCOUNTS_ERROR:
      return { loading: false, error: action.payload };
    default: {
      return state;
    }
  }
};

// RECEIPTS

export const createReceiptReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_RECEIPT_REQUEST:
      return { loading: true };
    case ADD_NEW_RECEIPT_SUCCESS:
      return { loading: false, success: true };
    case ADD_NEW_RECEIPT_ERROR:
      return { loading: false, error: action.payload };
    case ADD_NEW_RECEIPT_RESET:
      return {};
    default:
      return state;
  }
};

export const listReceiptsDataReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALL_RECEIPTS_LIST_REQUEST:
      return { loading: true };
    case FETCH_ALL_RECEIPTS_LIST_SUCCESS:
      return { loading: false, receiptsData: action.payload };
    case FETCH_ALL_RECEIPTS_LIST_ERROR:
      return { loading: false, error: action.payload };
    default: {
      return state;
    }
  }
};
