import {
  NOT_AUTHENTICATED,
  AUTHENTICATED,
  AUTH_ERROR,
  AUTHENTICATING,
} from '../constants/authConstants';

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTHENTICATING:
      return { loading: true };
    case AUTHENTICATED:
      return {
        loading: false,
        isAuth: true,
        loggedIn: true,
        user: action.payload,
      };
    case NOT_AUTHENTICATED:
      return {
        loading: false,
        isAuth: false,
        loggedIn: false,
        user: {},
      };
    case AUTH_ERROR:
      return { loading: false, isAuth: false, error: action.payload };
    default:
      return state;
  }
};
