import { notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportData } from '../../actions/reportsActions';
import TypoPanel from '../UI/TypoPanel';
import ReportsForm from './ReportsForm';
import * as numFormat from 'indian-number-format';
import ReportsDisplayPanel from './ReportsDisplayPanel';

const Reports = () => {
  const { loading, reportData, error, prevBalance } = useSelector(
    (state) => state.showReport
  );
  const dispatch = useDispatch();
  const [data, setData] = useState();

  useEffect(() => {
    if (reportData) {
      let reportsInfo = reportData.map((item, i) => {
        return {
          key: item.id,
          no: i + 1,
          date: item.date,
          weight: item.weight ? item.weight : '-',
          rate: item.rate ? item.rate : '-',
          amount: item.amount ? item.amount : '-',
          received: item.received ? item.received : '-',
          balance: 0,
        };
      });

      reportsInfo.reduce((p, c, i) => {
        const amount = isNaN(Number(c.amount)) ? 0 : Number(c.amount);
        const received = isNaN(Number(c.received))
          ? 0
          : Number(c.received);
        const oldBalance = isNaN(Number(prevBalance))
          ? 0
          : Number(prevBalance);
        const previousBalance = isNaN(Number(p.balance))
          ? 0
          : Number(p.balance);

        const balance =
          i === 0
            ? amount + oldBalance - received
            : previousBalance - received;

        c.balance = balance;
        return c;
      }, {});
      reportsInfo.unshift({
        key: 'prevBalance',
        no: `Opening Balance `,
        date: numFormat.formatFixed(prevBalance, 2),
        weight: '',
        rate: '',
        amount: '',
        received: 'Opening Balance',
        balance: numFormat.formatFixed(prevBalance, 2),
      });
      const closingBalance =
        reportsInfo[reportsInfo.length - 1].balance;

      reportsInfo.push({
        key: 'closingBalance',
        no: `Closing Balance `,
        date: numFormat.formatFixed(closingBalance, 2),
        weight: '',
        rate: '',
        amount: '',
        received: 'Closing Balance',
        balance: numFormat.formatFixed(closingBalance, 2),
      });
      setData(reportsInfo);
    }
  }, [reportData, prevBalance]);

  const errorNotify = useCallback(
    () =>
      notification.error({
        message: error,
      }),
    [error]
  );
  useEffect(() => {
    if (error) {
      errorNotify();
    }
  }, [error, errorNotify]);
  const submitHandler = (values) => {
    const { contact, date } = values;
    const [startDate, endDate] = date;
    dispatch(getReportData({ contact, startDate, endDate }));
  };
  return (
    <>
      <TypoPanel title='Reports'></TypoPanel>
      <ReportsForm
        onSubmit={submitHandler}
        loading={loading}
      ></ReportsForm>
      <ReportsDisplayPanel data={data}></ReportsDisplayPanel>
    </>
  );
};

export default Reports;
