import {
  LICENSE_UPLOAD_ERROR,
  LICENSE_UPLOAD_REQUEST,
  LICENSE_UPLOAD_RESET,
  LICENSE_UPLOAD_SUCCESS,
} from '../constants/licenseConstants';

export const uploadLicenseReducder = (state = {}, action) => {
  switch (action.type) {
    case LICENSE_UPLOAD_REQUEST:
      return { loading: true };
    case LICENSE_UPLOAD_SUCCESS:
      return { loading: false, success: true };
    case LICENSE_UPLOAD_ERROR:
      return { loading: false, error: action.payload };
    case LICENSE_UPLOAD_RESET:
      return {};
    default:
      return state;
  }
};
