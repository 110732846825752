import {
  NOT_AUTHENTICATED,
  AUTHENTICATED,
  AUTH_ERROR,
  AUTHENTICATING,
} from '../constants/authConstants';
import Axios from './utils/serverConnection';

export const checkAuth = () => async (dispatch) => {
  dispatch({ type: AUTHENTICATING });
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const token = user?.token;

  if (user) {
    try {
      const { data } = await Axios.get('/user/userInfo.php', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success === 1) {
        dispatch({ type: AUTHENTICATED, payload: data.user });
      } else if (data.success === 0) {
        dispatch({ type: NOT_AUTHENTICATED });
      }
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  } else {
    dispatch({ type: NOT_AUTHENTICATED });
  }
};
