import {
  ADD_NEW_CONTACT_ERROR,
  ADD_NEW_CONTACT_REQUEST,
  ADD_NEW_CONTACT_RESET,
  ADD_NEW_CONTACT_SUCCESS,
  FETCH_ALL_CONTACTS_ERROR,
  FETCH_ALL_CONTACTS_REQUEST,
  FETCH_ALL_CONTACTS_SUCCESS,
  REMOVE_CONTACT_ERROR,
  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_RESET,
  REMOVE_CONTACT_SUCCESS,
  UPDATE_CONTACT_ERROR,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_RESET,
  UPDATE_CONTACT_SUCCESS,
} from '../constants/contactConstants';

export const createContactReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_CONTACT_REQUEST:
      return { loading: true };
    case ADD_NEW_CONTACT_SUCCESS:
      return { loading: false, success: true };
    case ADD_NEW_CONTACT_ERROR:
      return { loading: false, error: action.payload };
    case ADD_NEW_CONTACT_RESET:
      return {};
    default:
      return state;
  }
};

export const listContactsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALL_CONTACTS_REQUEST:
      return { loading: true };
    case FETCH_ALL_CONTACTS_SUCCESS:
      return { loading: false, contacts: action.payload };
    case FETCH_ALL_CONTACTS_ERROR:
      return { loading: false, error: action.payload };
    default: {
      return state;
    }
  }
};
export const updateContactReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CONTACT_REQUEST:
      return { loading: true };
    case UPDATE_CONTACT_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_CONTACT_ERROR:
      return { loading: false, error: action.payload };
    case UPDATE_CONTACT_RESET:
      return {};
    default:
      return state;
  }
};

export const removeContactReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_CONTACT_REQUEST:
      return { loading: true };
    case REMOVE_CONTACT_SUCCESS:
      return { loading: false, success: true };
    case REMOVE_CONTACT_ERROR:
      return { loading: false, error: action.payload };
    case REMOVE_CONTACT_RESET:
      return {};
    default:
      return state;
  }
};
