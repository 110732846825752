import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from './reducers/authReducer';
import { userLoginReducer } from './reducers/userReducer';
import { uploadLicenseReducder } from './reducers/licenseReducer';
import {
  createContactReducer,
  listContactsReducer,
  removeContactReducer,
  updateContactReducer,
} from './reducers/contactReducer';

import { showReportReducer } from './reducers/reportReducers';
import {
  createReceiptReducer,
  listAccountsDataReducer,
  listReceiptsDataReducer,
  uploadAccountsReducer,
} from './reducers/accountsReducer';
const initialState = {
  userLogin: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null,
  },
};
const reducer = combineReducers({
  auth: authReducer,
  userLogin: userLoginReducer,
  uploadLicense: uploadLicenseReducder,
  createContact: createContactReducer,
  listContacts: listContactsReducer,
  updateContact: updateContactReducer,
  removeContact: removeContactReducer,
  uploadAccounts: uploadAccountsReducer,
  showReport: showReportReducer,
  createReceipt: createReceiptReducer,
  listAccountsData: listAccountsDataReducer,
  listReceiptsData: listReceiptsDataReducer,
});

const composeEnhancer =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
