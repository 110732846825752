export const ADD_NEW_CONTACT_REQUEST = 'ADD_NEW_CONTACT_REQUEST';
export const ADD_NEW_CONTACT_SUCCESS = 'ADD_NEW_CONTACT_SUCCESS';
export const ADD_NEW_CONTACT_ERROR = 'ADD_NEW_CONTACT_ERROR';
export const ADD_NEW_CONTACT_RESET = 'ADD_NEW_CONTACT_RESET';

export const FETCH_ALL_CONTACTS_REQUEST =
  'FETCH_ALL_CONTACTS_REQUEST';
export const FETCH_ALL_CONTACTS_SUCCESS =
  'FETCH_ALL_CONTACTS_SUCCESS';
export const FETCH_ALL_CONTACTS_ERROR = 'FETCH_ALL_CONTACTS_ERROR';

export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';
export const UPDATE_CONTACT_RESET = 'UPDATE_CONTACT_RESET';

export const REMOVE_CONTACT_REQUEST = 'REMOVE_CONTACT_REQUEST';
export const REMOVE_CONTACT_SUCCESS = 'REMOVE_CONTACT_SUCCESS';
export const REMOVE_CONTACT_ERROR = ' REMOVE_CONTACT_ERROR';
export const REMOVE_CONTACT_RESET = 'REMOVE_CONTACT_RESET';
