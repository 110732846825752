import { Alert, Spin } from 'antd';
import React from 'react';
import AccountsListTable from './AccountsListTable';

const AccountsList = (props) => {
  return (
    <>
      {props.loading ? (
        <Spin />
      ) : props.error ? (
        <Alert type='error' message={props.error} banner />
      ) : (
        <AccountsListTable
          data={props.accountsData}
          loading={props.loading}
        ></AccountsListTable>
      )}
    </>
  );
};

export default AccountsList;
