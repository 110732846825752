import {
  ADD_NEW_ACCOUNTS_ERROR,
  ADD_NEW_ACCOUNTS_REQUEST,
  ADD_NEW_ACCOUNTS_SUCCESS,
  ADD_NEW_RECEIPT_ERROR,
  ADD_NEW_RECEIPT_REQUEST,
  ADD_NEW_RECEIPT_SUCCESS,
  FETCH_ALL_ACCOUNTS_ERROR,
  FETCH_ALL_ACCOUNTS_REQUEST,
  FETCH_ALL_ACCOUNTS_SUCCESS,
  FETCH_ALL_RECEIPTS_LIST_ERROR,
  FETCH_ALL_RECEIPTS_LIST_REQUEST,
  FETCH_ALL_RECEIPTS_LIST_SUCCESS,
} from '../constants/accountsConstants';
import Axios from './utils/serverConnection';

export const saveAccountsData =
  (uploadedData) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    dispatch({ type: ADD_NEW_ACCOUNTS_REQUEST });
    try {
      const { data } = await Axios.post(
        '/accounts/uploadAccountsData.php',
        uploadedData,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      if (data.success === 1) {
        dispatch({
          type: ADD_NEW_ACCOUNTS_SUCCESS,
          payload: data.success,
        });
      } else if (data.success === 0) {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch({
        type: ADD_NEW_ACCOUNTS_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const addReceiptData =
  (receipt) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
    try {
      dispatch({ type: ADD_NEW_RECEIPT_REQUEST });

      const { data } = await Axios.post(
        '/accounts/addReceipt.php',
        receipt,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      if (data.success === 1) {
        dispatch({
          type: ADD_NEW_RECEIPT_SUCCESS,
          payload: data.success,
        });
      } else if (data.success === 0) {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch({
        type: ADD_NEW_RECEIPT_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getAccountsList =
  (filterValues) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
    dispatch({ type: FETCH_ALL_ACCOUNTS_REQUEST });

    try {
      const { data } = await Axios.get(
        '/accounts/getAllAccountsData.php',
        filterValues,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      if (data.success === 1) {
        dispatch({
          type: FETCH_ALL_ACCOUNTS_SUCCESS,
          payload: data.accountsData,
        });
      } else if (data.success === 0) {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch({
        type: FETCH_ALL_ACCOUNTS_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getReceiptsList =
  (filterValues) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
    dispatch({ type: FETCH_ALL_RECEIPTS_LIST_REQUEST });

    try {
      const { data } = await Axios.get(
        '/accounts/getAllReceiptsData.php',
        filterValues,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      if (data.success === 1) {
        dispatch({
          type: FETCH_ALL_RECEIPTS_LIST_SUCCESS,
          payload: data.receiptsData,
        });
      } else if (data.success === 0) {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch({
        type: FETCH_ALL_RECEIPTS_LIST_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
