import { Button, Dropdown, Menu } from 'antd';
import { BoxArrowInUp } from 'react-bootstrap-icons';
import React from 'react';

const ReportsExportPanel = () => {
  const hanleMenuClickHandler = (e) => {
    console.log(e);
  };
  const items = [
    {
      label: 'Export to Excel',
      key: 'excel',
    },
    {
      label: 'Save as PDF',
      key: 'pdf',
    },
  ];
  const menu = <Menu onClick={hanleMenuClickHandler} items={items} />;
  return (
    <>
      <Dropdown overlay={menu}>
        <Button>
          <BoxArrowInUp
            size={18}
            className='me-1'
            color='currentColor'
          ></BoxArrowInUp>
          Export
        </Button>
      </Dropdown>
    </>
  );
};

export default ReportsExportPanel;
