import React from 'react';
import TypoPanel from '../UI/TypoPanel';
import ReportsDataPanel from './ReportsDataPanel';
import ReportsExportPanel from './ReportsExportPanel';

const ReportsDisplayPanel = (props) => {
  return (
    <div className='py-5'>
      <TypoPanel title='Accounts Report'>
        <ReportsExportPanel data={props.data}></ReportsExportPanel>
      </TypoPanel>
      <ReportsDataPanel data={props.data}></ReportsDataPanel>
    </div>
  );
};

export default ReportsDisplayPanel;
