import {
  FETCH_REPORT_DATA_ERROR,
  FETCH_REPORT_DATA_REQUEST,
  FETCH_REPORT_DATA_SUCCESS,
} from '../constants/reportConstants';

export const showReportReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REPORT_DATA_REQUEST:
      return { loading: true };
    case FETCH_REPORT_DATA_SUCCESS:
      return {
        loading: false,
        reportData: action.payload.reportData,
        prevBalance: action.payload.prevBalance,
      };
    case FETCH_REPORT_DATA_ERROR:
      return { loading: false, error: action.payload };
    default: {
      return state;
    }
  }
};
