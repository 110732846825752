import { Alert, Spin } from 'antd';
import React from 'react';
import ReceiptsListTable from './ReceiptsListTable';

const ReceiptsList = (props) => {
  return (
    <>
      {props.loading ? (
        <Spin />
      ) : props.error ? (
        <Alert type='error' message={props.error} banner />
      ) : (
        <ReceiptsListTable
          data={props.receiptsData}
          loading={props.loading}
        ></ReceiptsListTable>
      )}
    </>
  );
};

export default ReceiptsList;
