import {
  ADD_NEW_CONTACT_ERROR,
  ADD_NEW_CONTACT_REQUEST,
  ADD_NEW_CONTACT_SUCCESS,
  FETCH_ALL_CONTACTS_ERROR,
  FETCH_ALL_CONTACTS_REQUEST,
  FETCH_ALL_CONTACTS_SUCCESS,
} from '../constants/contactConstants';
import Axios from './utils/serverConnection';

export const addContact = (contact) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  dispatch({ type: ADD_NEW_CONTACT_REQUEST });
  try {
    const { data } = await Axios.post(
      '/contact/addContact.php',
      contact,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );

    if (data.success === 1) {
      dispatch({
        type: ADD_NEW_CONTACT_SUCCESS,
        payload: data.success,
      });
    } else if (data.success === 0) {
      throw new Error(data.message);
    }
  } catch (error) {
    dispatch({
      type: ADD_NEW_CONTACT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllContacts = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_CONTACTS_REQUEST });

  try {
    const { data } = await Axios.get('/contact/getAllContacts.php');

    if (data.success === 1) {
      dispatch({
        type: FETCH_ALL_CONTACTS_SUCCESS,
        payload: data.contacts,
      });
    } else if (data.success === 0) {
      throw new Error(data.message);
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_CONTACTS_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
